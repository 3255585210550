import { CollapsibleStyled, CollapsibleHeader, CollapsibleChild } from "../../../../src/styles/Collapsible";
import GenericCard from "../../../../src/lib/GenericCard";
import StaticSiteJPG from "../../../../src/assets/static_site.png";
import GreenfieldJPG from "../../../../src/assets/windows_greenfield.jpg";
import * as React from 'react';
export default {
  CollapsibleStyled,
  CollapsibleHeader,
  CollapsibleChild,
  GenericCard,
  StaticSiteJPG,
  GreenfieldJPG,
  React
};