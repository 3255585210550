import { CollapsibleComponent, CollapsibleHead, CollapsibleContent } from '../lib/Collapsible'
import chronoblogTheme from '../gatsby-plugin-theme-ui'

import styled from "@emotion/styled"
import { css } from "@emotion/core"

const CollapsibleStyled = styled(CollapsibleComponent)`
`
const CollapsibleHeader = styled(CollapsibleHead)`
padding: 5px;
font-size: 18px;
color: black;
background-color: ${chronoblogTheme.congrammerTheme};
`

const CollapsibleChild = styled(CollapsibleContent)`
font-size: 16px;
color: ${chronoblogTheme.congrammerTheme};
background-color: black;
div {
padding-bottom: 2px;
padding-top: 2px;
}
`


export { CollapsibleStyled, CollapsibleHeader, CollapsibleChild }


