import chronoblogTheme from 'gatsby-theme-chronoblog/src/gatsby-plugin-theme-ui';
const conTheme = '#FFC83D'

export default {
  congrammerTheme: conTheme,
  ...chronoblogTheme,
  initialColorMode: 'light',
  colors: {
    ...chronoblogTheme.color,
    text: '#000',
    background: '#fff',
    link: '#3d7e9a',
    primary: '#3a5f7d',
    secondary: '#5a809e',
    muted: '#dae1e3',
    modes: {
      dark: {
        ...chronoblogTheme.colors.modes.dark,
        text: conTheme,
        background: '#0e0f17',
        muted: '#161b1d'
      }
    }
  },

  fontSizes: [14, 16, 18, 20, 22, 24, 28, 36],
  borderRadius: {
    ...chronoblogTheme.borderRadius,
    card: 6,
    button: 6,
    search: 6,
    code: 6,
    img: 6
  },

  fonts: {
    ...chronoblogTheme.fonts,
    body: '-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },

  styles: {
    ...chronoblogTheme.styles,
    Footer: {
      color: 'text',
      bg: 'background'
    }
  }
};
